<template>
  <div class="mb-3">
    <div  class="form-group form-group-lg" label="Website address:" label-for="input-1"
      description="">
      <input id="input-1" v-model="websiteUrl" class="form-control" type="text" required
        placeholder="e.g https://www.example.com" />
    </div>

    <div id="errorMsg" v-show="errorMessages.length > 0">
      <ul class="list-group">
        <li v-for="error in errorMessages" :key="error.id" class="list-group-item list-group-item-danger">
          {{ error }}</li>
      </ul>
    </div>
  </div>

</template>

<script>
import api from '@/services/secureHttps';

export default {
  props: ["store"],
  data() {
    return {
      websiteUrl: "",
      errorMessages: []
    };
  },
  methods: {
    nextStep() {
      // this.store.setState('websiteUrl', this.websiteUrl);
      return new Promise((resolve, reject) => {
        this.$emit("loading", true);

        api.addWebsite(this.websiteUrl)
          .then((response) => {
            console.log("response:", response);
             this.store.setState('managerScript', response.data.managerScript);
            this.$emit("loading", false);
            this.errorMessages = [];
            return resolve()
          }, error => {

            let responseData = error.response.data;
            this.errorMessages = [];

            for (let i = 0; i < responseData.errors.length; i++) {
              var errorGroup = responseData.errors[i];

              for (let j = 0; j < errorGroup.description.length; j++) {
                this.errorMessages.push(errorGroup.description[j]);
              }
            }
            this.$emit("error", true);
            reject({ error: "invalid url" });
          });
      });
    }
  }

    //nextStep() {
    /*if (this.email.length > 0) return true;

      this.$bvToast.toast(`El formluario tiene campos obligatorios`, {
        title: "Error",
        autoHideDelay: 2000,
        variant: "danger",
        toaster: "b-toaster-bottom-right"
      });
      this.$emit("error", true);
      return false;*/
    //}

  };
</script>

<style>
</style>
