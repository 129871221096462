<script>
import stepper from "@/components/stepper";
import registerWebsite from "./registerWebsite.vue";
import registerClientScript from "./registerClientScript.vue";

import { shallowRef } from 'vue';

export default {
    components: {
        stepper
    },

    data() {
        return {
            email: "",
            password: "",
            errorMessages: [],
            steps: shallowRef([
                {
                    name: "Your website",
                    desc:
                        "Enter your website to get going.",
                    icon: "fas fa-car",
                    disabled: false,
                    active: true,
                    component: registerWebsite
                },
                {
                    name: "Client code",
                    desc:
                        "Add this script code to your website to start making changes",
                    disabled: false,
                    active: false,
                    confirm: 'Done',
                    component: registerClientScript
                }
            ])
        }
    },

    methods: {
        onRegistrationCompleted() {
            console.log("completed");
            this.$router.push({ name: 'default' });
        }
    }
}
</script>

<template>
    <div class="auth-page-wrapper pt-5">
        <!-- auth page bg -->
        <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
            <div class="bg-overlay"></div>

            <div class="shape">

                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 1440 120">
                    <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
                </svg>
            </div>
        </div>

        <!-- auth page content -->
        <div class="auth-page-content">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">

                        <div class="text-center mt-sm-5 mb-4 text-white-50">
                            <div>
                                <router-link to="/" class="d-inline-block auth-logo">
                                    <img src="@/assets/images/logo-light.png" alt="" height="20" />
                                </router-link>
                            </div>
                            <p class="mt-3 fs-15 fw-semibold">
                                Premium Admin & Dashboard Template
                            </p>
                        </div>
                    </div>
                </div>
                <!-- end row -->
                <div class="row justify-content-center">
                    <div class="col-md-8 col-lg-6 col-xl-5">
                        <div class="card mt-4">
                            <div class="card-body p-4">
                                <stepper :steps="steps" :initial-state="{}" @confirm="onRegistrationCompleted">
                                    <template #fatal-error="{ errorMsg }">{{ errorMsg }}</template>
                                </stepper>

                            </div>
                            <!-- end card body -->
                        </div>
                        <!-- end card -->

                    </div>
                </div>
                <!-- end row -->
            </div>
            <!-- end container -->
        </div>
        <!-- end auth page content -->

        <!-- footer -->
        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center">
                            <p class="mb-0 text-muted">
                                &copy; {{ new Date().getFullYear() }} Semantic Click.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <!-- end Footer -->
    </div>
    <!-- end auth-page-wrapper -->
</template>