<template>
    <div class="row mb-3">
        <div class="col-md-8 col-lg-6 col-xl-5 text-truncate">
            <p class=" text-break text-body">Copy the script into every page of your website.</p>
        </div>

        <div class="row">
            <div class="col">
                
                    <div class="form-group">
                        <input v-on:focus="$event.target.select()" class="form-control" ref="clone" readonly
                            v-model="script" />

                    </div>
                    <button @click="copy" class="btn btn-secondary">Copy</button>
               
            </div>
        </div>
    </div>

</template>

<script>
export default {
    props: ["store"],
    data() {
        return {
            script: this.store.state.managerScript
        };
    },
    methods: {
        copy() {

            console.log(this.store.state.managerScript, this.store);
            this.$refs.clone.focus();
            document.execCommand('copy');
        },

        submitForm() {

        }
    }
};
</script>


<style>
</style>
